$(document).ready(() => {
    // General
    // Data prefill
    urlParams = new URLSearchParams(window.location.search);
    myParam = urlParams.get('myParam');

    function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    // Scroll to
    /**
     * Scroll to anywhere.
     * @deprecated
     *
     * @param  mixed    to
     * @param  integer  offset
     * @param  integer  speed
     * @return void
     */
    $.scroll_to = function (to, offset, speed) {
        let options = {
            scrollTo: 0,
            scrollOffset: 50,
            scrollSpeed: 300,
        };

        if (typeof to === 'object') {
            if (!to.scrollTo) delete to.scrollTo;
            options = $.extend(options, to);
        } else {
            options.scrollTo = to || 0;
            options.scrollOffset = offset || 0;
            options.scrollSpeed = speed || 500;
        }

        if (typeof options.scrollTo === 'string') options.scrollTo = $(options.scrollTo).offset().top;

        if (typeof options.scrollTo !== 'number') return; // must have some position

        $('html, body').animate({ scrollTop: (options.scrollTo - options.scrollOffset) }, options.scrollSpeed);
    };
    /**
     * Scroll to(p) triggers.
     */
    $('[data-scroll-to], [data-scroll-top]').on('click', function (event) {
        event.preventDefault();
        $.scroll_to($(this).data());
    });
    // Labels
    $('input').on('keydown', function () {
        $(this).siblings('label').css({
            opacity: '1',
            animation: '0.5s ease 0s normal forwards 1 fadeInUp',
        });
    });
    $('input').on('blur', function () {
        if (!$(this).val()) {
            $(this).siblings('label').css({
                opacity: '0',
                animation: '0.5s ease 0s normal forwards 1 fadeOut',
            });
        }
    });
    // Embolden Select
    $('select').on('change', function () {
        $(this).css({
            'font-weight': '700',
            'font-size': '17px',
        });
    });
    if ($('#input-employees').val()) {
        $('#input-employees').css({
            'font-weight': '700',
            'font-size': '17px',
        });
    }
    if ($('#input-budget').val()) {
        $('#input-budget').css({
            'font-weight': '700',
            'font-size': '17px',
        });
    }
    if ($('#input-company_type').val()) {
        $('#input-company_type').css({
            'font-weight': '700',
            'font-size': '17px',
        });
    }
    if ($('#input-role').val()) {
        $('#input-role').css({
            'font-weight': '700',
            'font-size': '17px',
        });
    }
    if ($('#input-kwanko_office').val()) {
        $('#input-kwanko_office').css({
            'font-weight': '700',
            'font-size': '17px',
        });
    }
    // Select checkbox on close
    $('#accept_tc').on('click', () => {
        $('#input-terms').prop('checked', true);
    });
    // Mobile Menu Open
    $('.navbar-toggler').on('click', () => {
        $('.mobile-nav').animate({ right: '0' }, 500);
        $('.close').animate({ right: '10' }, 500);
    });
    $('.close').on('click', () => {
        $('.mobile-nav').animate({ right: '-320' }, 500);
        $('.close').animate({ right: '-320' }, 500);
    });
    // Hamburger Animation
    $('.hamburger').on('mouseenter', function () {
        $(this).addClass('is-active');
    });
    $('.hamburger').on('mouseleave click', function () {
        $(this).removeClass('is-active');
    });
    // Register Options
    $('#register_options').on('click', function () {
        $(this).siblings('.options').toggleClass('d-none');
    });
    // Login Options
    $('#login_options').on('click', function () {
        $(this).siblings('.options').toggleClass('d-none');
    });
    // Hide both Login and Register Options
    $('.notification').on('mouseleave', () => {
        $('#register_options').siblings('.options').addClass('d-none');
        $('#login_options').siblings('.options').addClass('d-none');
    });
    $('#register_options').on('mouseover', () => {
        $('#login_options').siblings('.options').addClass('d-none');
    });
    $('#login_options').on('mouseover', () => {
        $('#register_options').siblings('.options').addClass('d-none');
    });
    // Hide Unused Langauges
    $('.mlp-language-box.mlp_language_box > ul > li > a[href*="kwanko.com/br/"]').parent('li').css('padding', '0');
    $('.mlp-language-box.mlp_language_box > ul > li > a[href*="kwanko.com/it/"]').parent('li').css('padding', '0');
    $('.mlp-language-box.mlp_language_box > ul > li > a[href*="kwanko.com/benl/"]').parent('li').css('padding', '0');
    $('.mlp-language-box.mlp_language_box > ul > li > a[href*="kwanko.com/befr/"]').parent('li').css('padding', '0');
    $('.mlp-language-box.mlp_language_box > ul > li > a[href*="kwanko.com/de/"]').parent('li').css('padding', '0');
    $('.mlp-language-box.mlp_language_box > ul > li > a[href*="kwanko.com/es/"]').parent('li').css('padding', '0');
    $('.mlp-language-box.mlp_language_box > ul > li > a[href*="kwanko.com/mx/"]').parent('li').css('padding', '0');
    $('.mlp-language-box.mlp_language_box > ul > li > a[href*="kwanko.com/pl/"]').parent('li').css('padding', '0');
    // Open Contact Modal with URL Parameter
    if (getParameterByName('contact') == 'true') {
        $('#contact').modal('toggle');
    }
    // Menu
    // Desktop
    $('.links').on('mouseenter', function () {
        $(this).children('.submenu').removeClass('d-none');
    });
    $('.links').on('mouseleave', function () {
        $(this).children('.submenu').addClass('d-none');
    });
    // Sticky on Scroll
    $(window).on('scroll resize load', () => {
        const scroll = $(window).scrollTop();
        if (scroll > 25) {
            $('.navigation').addClass('sticky');
        } else {
            $('.navigation').removeClass('sticky');
        }
    });
    // Mobile
    $('.navbar-toggler').on('click', () => {
        $('.mobilesidebar').toggleClass('open');
    });
    $('.mobilesidebar-link.moreinfo').on('click', function () {
        $(this).toggleClass('open');
        $(this).siblings('.mobilesidebar-submenu').toggleClass('d-none');
    });
    $('.mobilesidebar-sublink.moreinfo').on('click', function () {
        $(this).toggleClass('open');
        $(this).siblings('.mobilesidebar-grandchildsubmenu').toggleClass('d-none');
    });
    // Languages
    $('.mobilesidebar-current a').attr('href', '#!');
    $('.mobilesidebar-current').on('click', function () {
        $(this).toggleClass('open');
        $('.mobilesidebar-langs').toggleClass('d-none');
    });
    // Sticky on Scroll
    $(window).on('scroll resize load', () => {
        const scroll = $(window).scrollTop();
        if (scroll > 50) {
            $('.mobilenavigation').addClass('sticky');
        } else {
            $('.mobilenavigation').removeClass('sticky');
        }
    });
    // Cookies
    // General
    if (!$.cookie('cookie-accept')) {
        $('.cookie').show();
        $('[data-dismiss=cookie]').click(() => {
            $.cookie('cookie-accept', 1);
            $('.cookie').hide();
        });
    } else {
        $('head').append('<script type="text/javascript">adroll_adv_id="GY3PHHSNB5FJDH2Z6DXJ6B",adroll_pix_id="RDZBXDRHUVGEJOEGI3VCD5",function(){var t=function(){if(!document.readyState||/loaded|complete/.test(document.readyState)){if(!window.__adroll_loaded)return __adroll_loaded=!0,void setTimeout(t,50);var e=document.createElement("script"),d="https:"==document.location.protocol?"https://s.adroll.com":"http://a.adroll.com";e.setAttribute("async","true"),e.type="text/javascript",e.src=d+"/j/roundtrip.js",((document.getElementsByTagName("head")||[null])[0]||document.getElementsByTagName("script")[0].parentNode).appendChild(e)}else setTimeout(t,10)};window.addEventListener?window.addEventListener("load",t,!1):window.attachEvent("onload",t)}();</script>');
        $('head').append('<script type="text/javascript">!function(e,t,n,c,o,a,f){e.fbq||(o=e.fbq=function(){o.callMethod?o.callMethod.apply(o,arguments):o.queue.push(arguments)},e._fbq||(e._fbq=o),o.push=o,o.loaded=!0,o.version="2.0",o.queue=[],(a=t.createElement(n)).async=!0,a.src="https://connect.facebook.net/en_US/fbevents.js",(f=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,f))}(window,document,"script"),fbq("init","658842007639719"),fbq("track","PageView");</script>');
        // $('head').append('<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=658842007639719&ev=PageView&noscript=1"/>')
        $('body').append('<img height="1" width="1" style="display:none" src="https://dc.ads.linkedin.com/collect/?pid=412002&fmt=gif" />');
        $('body').append('<script type="text/javascript">_linkedin_partner_id="412002",window._linkedin_data_partner_ids=window._linkedin_data_partner_ids||[],window._linkedin_data_partner_ids.push(_linkedin_partner_id);</script><script type="text/javascript">!function(){var t=document.getElementsByTagName("script")[0],e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://snap.licdn.com/li.lms-analytics/insight.min.js",t.parentNode.insertBefore(e,t)}();</script>');
        $('body').append('<script type="text/javascript">!function(e,t,n,i,a,c){e.twq||((i=e.twq=function(){i.exe?i.exe.apply(i,arguments):i.queue.push(arguments)}).version="1.1",i.queue=[],(a=t.createElement(n)).async=!0,a.src="//static.ads-twitter.com/uwt.js",(c=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,c))}(window,document,"script"),twq("init","nvxqu"),twq("track","PageView");</script>');
    }
    // On Form
    expiry = new Date();
    expiry.setTime(expiry.getTime() + (365 * 24 * 60 * 60 * 1000));
    if (getParameterByName('utm_source')) {
        document.cookie = `${'_koookie_' + ' utm_source='}${getParameterByName('utm_source')} utm_campaign=${getParameterByName('utm_campaign')} utm_medium=${getParameterByName('utm_medium')} utm_content=${getParameterByName('utm_content')} utm_term=${getParameterByName('utm_term')} pubid=${getParameterByName('pubid')}; expires=${expiry.toGMTString()}`;
    }
    let array = [];
    array = document.cookie.split(';');
    const result = array.filter((el) => el.includes('_koookie_') === true);
    const string = result.toString();
    const clean_result = string.slice(9);
    $('input[name="cookie"]').val(clean_result);
    // Modal
    $('#adroll').one('click', () => {
        $('head').append('<script type="text/javascript">adroll_adv_id="GY3PHHSNB5FJDH2Z6DXJ6B",adroll_pix_id="RDZBXDRHUVGEJOEGI3VCD5",function(){var t=function(){if(!document.readyState||/loaded|complete/.test(document.readyState)){if(!window.__adroll_loaded)return __adroll_loaded=!0,void setTimeout(t,50);var e=document.createElement("script"),d="https:"==document.location.protocol?"https://s.adroll.com":"http://a.adroll.com";e.setAttribute("async","true"),e.type="text/javascript",e.src=d+"/j/roundtrip.js",((document.getElementsByTagName("head")||[null])[0]||document.getElementsByTagName("script")[0].parentNode).appendChild(e)}else setTimeout(t,10)};window.addEventListener?window.addEventListener("load",t,!1):window.attachEvent("onload",t)}();</script>');
    });
    $('#facebook').one('click', () => {
        $('head').append('<script type="text/javascript">!function(e,t,n,c,o,a,f){e.fbq||(o=e.fbq=function(){o.callMethod?o.callMethod.apply(o,arguments):o.queue.push(arguments)},e._fbq||(e._fbq=o),o.push=o,o.loaded=!0,o.version="2.0",o.queue=[],(a=t.createElement(n)).async=!0,a.src="https://connect.facebook.net/en_US/fbevents.js",(f=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,f))}(window,document,"script"),fbq("init","658842007639719"),fbq("track","PageView");</script>');
        // $('body').append('<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=658842007639719&ev=PageView&noscript=1"/>')
    });
    $('#linkedin').one('click', () => {
        $('body').append('<script type="text/javascript">_linkedin_partner_id="412002",window._linkedin_data_partner_ids=window._linkedin_data_partner_ids||[],window._linkedin_data_partner_ids.push(_linkedin_partner_id);</script><script type="text/javascript">!function(){var t=document.getElementsByTagName("script")[0],e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://snap.licdn.com/li.lms-analytics/insight.min.js",t.parentNode.insertBefore(e,t)}();</script>');
    });
    $('#google').one('click', () => {
        $('head').append('<script async src="https://www.googletagmanager.com/gtag/js?id=UA-121410254-3"></script><script>function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("js",new Date),gtag("config","UA-121410254-3"),gtag("config","AW-978458619");</script>');
    });
    $('#twitter').one('click', () => {
        $('body').append('<script type="text/javascript">!function(e,t,n,i,a,c){e.twq||((i=e.twq=function(){i.exe?i.exe.apply(i,arguments):i.queue.push(arguments)}).version="1.1",i.queue=[],(a=t.createElement(n)).async=!0,a.src="//static.ads-twitter.com/uwt.js",(c=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,c))}(window,document,"script"),twq("init","nvxqu"),twq("track","PageView");</script>');
    });
    $('.allow-all').one('click', () => {
        $('head').append('<script type="text/javascript">adroll_adv_id="GY3PHHSNB5FJDH2Z6DXJ6B",adroll_pix_id="RDZBXDRHUVGEJOEGI3VCD5",function(){var t=function(){if(!document.readyState||/loaded|complete/.test(document.readyState)){if(!window.__adroll_loaded)return __adroll_loaded=!0,void setTimeout(t,50);var e=document.createElement("script"),d="https:"==document.location.protocol?"https://s.adroll.com":"http://a.adroll.com";e.setAttribute("async","true"),e.type="text/javascript",e.src=d+"/j/roundtrip.js",((document.getElementsByTagName("head")||[null])[0]||document.getElementsByTagName("script")[0].parentNode).appendChild(e)}else setTimeout(t,10)};window.addEventListener?window.addEventListener("load",t,!1):window.attachEvent("onload",t)}();</script>');
        $('head').append('<script type="text/javascript">!function(e,t,n,c,o,a,f){e.fbq||(o=e.fbq=function(){o.callMethod?o.callMethod.apply(o,arguments):o.queue.push(arguments)},e._fbq||(e._fbq=o),o.push=o,o.loaded=!0,o.version="2.0",o.queue=[],(a=t.createElement(n)).async=!0,a.src="https://connect.facebook.net/en_US/fbevents.js",(f=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,f))}(window,document,"script"),fbq("init","658842007639719"),fbq("track","PageView");</script>');
        // $('head').append('<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=658842007639719&ev=PageView&noscript=1"/>')
        $('body').append('<script type="text/javascript">_linkedin_partner_id="412002",window._linkedin_data_partner_ids=window._linkedin_data_partner_ids||[],window._linkedin_data_partner_ids.push(_linkedin_partner_id);</script><script type="text/javascript">!function(){var t=document.getElementsByTagName("script")[0],e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://snap.licdn.com/li.lms-analytics/insight.min.js",t.parentNode.insertBefore(e,t)}();</script>');
        $('body').append('<script type="text/javascript">!function(e,t,n,i,a,c){e.twq||((i=e.twq=function(){i.exe?i.exe.apply(i,arguments):i.queue.push(arguments)}).version="1.1",i.queue=[],(a=t.createElement(n)).async=!0,a.src="//static.ads-twitter.com/uwt.js",(c=t.getElementsByTagName(n)[0]).parentNode.insertBefore(a,c))}(window,document,"script"),twq("init","nvxqu"),twq("track","PageView");</script>');
        $.cookie('cookie-accept', 1);
        $('.cookie').hide();
    });
    $('.cookies-dismiss').one('click', () => {
        $('#cookies_modal').modal('toggle');
    });
});
